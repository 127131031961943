<template>
  <div>
    <navbar active="home" :apk-link-shown="apkLinkShown"/>
    <fullpage :apk-link-shown="apkLinkShown"/>
    <nav class="nav-end navbar hidden-xs">
      <div class="container clearfix">
        <!--banner container-->
        <div class="collapse navbar-collapse" id="mico-navbar-collapse-1">
          <ul class="nav navbar-nav">
            <li><a href="/terms/terms.html">{{ text.terms }}</a></li>
            <li class="line"><a href="javascript:void;">|</a></li>
            <li><a href="/terms/privacy.html">{{ text.privacy }}</a></li>
            <li class="line"><a href="javascript:void;">|</a></li>
            <li>
              <div class="dropup" id="lang-select">
                <button  class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ text.policies || 'Policies' }}&nbsp;&nbsp;<span id="chTriangle" class="caret"></span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <li class="en"><a href="/terms/sexual-abuse-support.html">{{ text.sexual_abuse_support || 'Sexual Abuse Support' }}</a></li>
                  <li class="en"><a href="/terms/sexual-abuse-support.html#csam">{{ text.child_safety_policy || 'Child Safety Policy' }}</a></li>
                </ul>
              </div>
            </li>
            <li class="line"><a href="javascript:void;">|</a></li>
            <li><a :href="text.about_link">{{ text.about }}</a></li>
            <li class="line"><a href="javascript:void;">|</a></li>
            <li>
              <div class="dropup" id="lang-select">
                <button  class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ language.label || 'English' }}&nbsp;&nbsp;<span id="chTriangle" class="caret"></span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <li v-for="({value, label}) in languages" :key="value">
                    <a :class="value" :href="`/?lang=${value}`">{{ label }}</a>
                  </li>
                  <!--
                  <li class="en"><a href="/en/home">English</a></li>
                  <li class="zh"><a href="/cn/home">中文</a></li>
                  <li class="th"><a href="/th/home">ภาษา</a></li>
                  <li class="id"><a href="/id/home">Indonesia</a></li>
                  <li class="tw"><a href="/zh-TW/home">繁體</a></li>
                  -->
                </ul>
              </div>
            </li>
          </ul>
          <ul class="nav navbar-nav navbar-right share_group">
            <li><a class="mico-width" href="https://www.facebook.com/micoapp"><img  src="/img/website/facebook.png" alt="Mico Facebook"/></a></li>
            <li><a class="mico-width" href="https://twitter.com/micoapp"><img src="/img/website/twitter.png" alt="Mico Twitter"/></a></li>
            <li><a class="mico-width" href="https://plus.google.com/104407370352973871981"><img src="/img/website/google.png" alt="Mico Google Plus"/></a></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
  import Navbar from '../../common/components/Navbar'
  import Fullpage from './Fullpage'
  export default {
    components: { Navbar, Fullpage },
    data() {
      const apkLinkShown = window.location.pathname !== '/th'
      return {
        apkLinkShown,
        languages: [],
        language: {}
      }
    },
    computed: {
      text() {
        return window.text
      },
//      language() {
//        return window.languages.filter((item) => {
//          return item.value === this.language
//        })[0]
//      },
//      languages() {
//        return window.languages.filter((item) => {
//          return item.value !== this.language
//        })
//      }
    },
    created() {
      this.languages = window.languages.filter((item) => {
        if(item.value === window.document.documentElement.lang) {
          this.language = item
        } else {
          return true
        }
      })
    }
  }
</script>
<style lang="less">
@import "../css/index.less";
</style>

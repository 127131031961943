var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar navbar-fixed-top", attrs: { role: "navigation" } },
    [
      _c("div", { staticClass: "container hidden-xs" }, [
        _c("div", { staticClass: "navbar-header" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "navbar-brand", attrs: { href: _vm.logoLink } },
            [
              _c("img", {
                staticClass: "logo",
                attrs: { src: _vm.logo, alt: "logo" },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "collapse navbar-collapse" }, [
          _c("ul", { staticClass: "nav navbar-nav navbar-left" }, [
            _c("li", { class: _vm.active === "home" ? "active" : "" }, [
              _c("a", { attrs: { href: _vm.homeLink } }, [
                _vm._v(_vm._s(_vm.text.home_page)),
              ]),
            ]),
            _vm._v(" "),
            _vm.showFeed
              ? _c("li", { class: _vm.active === "hot" ? "active" : "" }, [
                  _c("a", { attrs: { href: "/hot" } }, [
                    _vm._v(_vm._s(_vm.text.hot_live)),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "nav navbar-nav navbar-right" }, [
            _vm.apkLinkShown
              ? _c("li", [
                  _c(
                    "a",
                    { attrs: { href: "javascript:void(0);" } },
                    [
                      _c("i", { staticClass: "app-icon" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("App")]),
                      _vm._v(" "),
                      _c("download-tooltip", {
                        staticClass: "dt",
                        attrs: { position: "top" },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showRechargeButton
              ? _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://web-app.micoworld.net/web/pay.html",
                      },
                    },
                    [
                      _c("i", { staticClass: "recharge-icon" }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.text.recharge))]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container visible-xs-block mobile" }, [
        _c("div", { staticClass: "navbar-header" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "menu",
              staticStyle: { position: "absolute", width: "100%" },
            },
            [
              _c("ul", { staticClass: "head-group" }, [
                _c("li", { class: _vm.active === "home" ? "active" : "" }, [
                  _c("a", { attrs: { href: "/" } }, [
                    _vm._v(_vm._s(_vm.text.home_page)),
                  ]),
                ]),
                _vm._v(" "),
                _vm.showFeed
                  ? _c("li", { class: _vm.active === "hot" ? "active" : "" }, [
                      _c("a", { attrs: { href: "/hot" } }, [
                        _vm._v(_vm._s(_vm.text.hot_live)),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.showRechargeButton
                ? _c("div", { staticClass: "recharge" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://web-app.micoworld.net/web/pay.html",
                        },
                      },
                      [_vm._v(_vm._s(_vm.text.recharge))]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "collapse navbar-collapse" }, [
          _c("ul", { staticClass: "nav navbar-nav" }, [
            _c("li", { staticClass: "languageBtn" }, [
              _c(
                "a",
                {
                  staticStyle: {
                    "padding-top": "5px",
                    "padding-bottom": "5px",
                  },
                  attrs: { href: "javascript:void(0);" },
                },
                [_vm._v(_vm._s(_vm.language.label || "English"))]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticStyle: {
                    "padding-top": "5px",
                    "padding-bottom": "5px",
                  },
                  attrs: { href: "/terms/terms.html" },
                },
                [_vm._v(_vm._s(_vm.text.terms))]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticStyle: {
                    "padding-top": "5px",
                    "padding-bottom": "5px",
                  },
                  attrs: { href: "/terms/privacy.html" },
                },
                [_vm._v(_vm._s(_vm.text.privacy))]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticStyle: {
                    "padding-top": "5px",
                    "padding-bottom": "5px",
                  },
                  attrs: { href: "javascript:void(0);" },
                },
                [_vm._v(_vm._s(_vm.text.policies || "Policies"))]
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass: "list",
                  staticStyle: { "list-style-type": "disc" },
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "list-item",
                      staticStyle: { "line-height": "1.3" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { "font-size": "12px", color: "#666" },
                          attrs: { href: "/terms/sexual-abuse-support.html" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.text.sexual_abuse_support ||
                                "Sexual Abuse Support"
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass: "list-item",
                      staticStyle: { "line-height": "1.3" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { "font-size": "12px", color: "#666" },
                          attrs: {
                            href: "/terms/sexual-abuse-support.html#csam",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.text.child_safety_policy ||
                                "Child Safety Policy"
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticStyle: {
                    "padding-top": "5px",
                    "padding-bottom": "5px",
                  },
                  attrs: { href: _vm.text.about_link },
                },
                [_vm._v(_vm._s(_vm.text.about))]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "languageList" },
        _vm._l(_vm.languages, function ({ value, label }) {
          return _c("li", { key: value, class: value }, [
            _c("a", { class: value, attrs: { href: `/?lang=${value}` } }, [
              _vm._v(_vm._s(label)),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggle collapsed",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": ".navbar-collapse",
        },
      },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Toggle navigation")]),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggle collapsed",
        staticStyle: { "z-index": "1" },
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": ".navbar-collapse",
        },
      },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Toggle navigation")]),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", {
        attrs: { active: "home", "apk-link-shown": _vm.apkLinkShown },
      }),
      _vm._v(" "),
      _c("fullpage", { attrs: { "apk-link-shown": _vm.apkLinkShown } }),
      _vm._v(" "),
      _c("nav", { staticClass: "nav-end navbar hidden-xs" }, [
        _c("div", { staticClass: "container clearfix" }, [
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "mico-navbar-collapse-1" },
            },
            [
              _c("ul", { staticClass: "nav navbar-nav" }, [
                _c("li", [
                  _c("a", { attrs: { href: "/terms/terms.html" } }, [
                    _vm._v(_vm._s(_vm.text.terms)),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("li", [
                  _c("a", { attrs: { href: "/terms/privacy.html" } }, [
                    _vm._v(_vm._s(_vm.text.privacy)),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "div",
                    { staticClass: "dropup", attrs: { id: "lang-select" } },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default dropdown-toggle",
                          attrs: {
                            type: "button",
                            id: "dropdownMenu1",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.text.policies || "Policies") + "  "
                          ),
                          _c("span", {
                            staticClass: "caret",
                            attrs: { id: "chTriangle" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "dropdownMenu2" },
                        },
                        [
                          _c("li", { staticClass: "en" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "/terms/sexual-abuse-support.html",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.text.sexual_abuse_support ||
                                      "Sexual Abuse Support"
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "en" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "/terms/sexual-abuse-support.html#csam",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.text.child_safety_policy ||
                                      "Child Safety Policy"
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("li", [
                  _c("a", { attrs: { href: _vm.text.about_link } }, [
                    _vm._v(_vm._s(_vm.text.about)),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "div",
                    { staticClass: "dropup", attrs: { id: "lang-select" } },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default dropdown-toggle",
                          attrs: {
                            type: "button",
                            id: "dropdownMenu1",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.language.label || "English") + "  "
                          ),
                          _c("span", {
                            staticClass: "caret",
                            attrs: { id: "chTriangle" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "dropdownMenu2" },
                        },
                        _vm._l(_vm.languages, function ({ value, label }) {
                          return _c("li", { key: value }, [
                            _c(
                              "a",
                              {
                                class: value,
                                attrs: { href: `/?lang=${value}` },
                              },
                              [_vm._v(_vm._s(label))]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(4),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "line" }, [
      _c("a", { attrs: { href: "javascript:void;" } }, [_vm._v("|")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "line" }, [
      _c("a", { attrs: { href: "javascript:void;" } }, [_vm._v("|")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "line" }, [
      _c("a", { attrs: { href: "javascript:void;" } }, [_vm._v("|")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "line" }, [
      _c("a", { attrs: { href: "javascript:void;" } }, [_vm._v("|")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "ul",
      { staticClass: "nav navbar-nav navbar-right share_group" },
      [
        _c("li", [
          _c(
            "a",
            {
              staticClass: "mico-width",
              attrs: { href: "https://www.facebook.com/micoapp" },
            },
            [
              _c("img", {
                attrs: {
                  src: "/img/website/facebook.png",
                  alt: "Mico Facebook",
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "mico-width",
              attrs: { href: "https://twitter.com/micoapp" },
            },
            [
              _c("img", {
                attrs: { src: "/img/website/twitter.png", alt: "Mico Twitter" },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "mico-width",
              attrs: { href: "https://plus.google.com/104407370352973871981" },
            },
            [
              _c("img", {
                attrs: {
                  src: "/img/website/google.png",
                  alt: "Mico Google Plus",
                },
              }),
            ]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }